import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './wishlist.scss';
import { Table } from 'react-bootstrap';

const NikkeGuidesGamemodesSimluationPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Simulation Room</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_sim.png"
            alt="Simulation Room"
          />
        </div>
        <div className="page-details">
          <h1>Simulation Room</h1>
          <h2>Guide for the Simulation Room in NIKKE.</h2>
          <p>
            Last updated: <strong>30/03/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Simulation Room" />
        <p>
          If you played AFK Arena, then the Simulation Room will feel very
          familiar as it's inspired by the Labyrinth mode in that game. In this
          mode, you have to navigate through Sectors - split into stages - at
          the end of which a boss awaits you. If you manage to kill the boss,
          the next Sector will open. Also, with each cleared stage, the power of
          enemies increases and to make it easier for you to deal with them, you
          will be able to collect and combine Buffs.
        </p>
        <p>
          To unlock this mode, you have to clear Chapter 1 and it's the main
          source of materials needed to upgrade the skills of your characters.
        </p>
        <SectionHeader title="Difficulties" />
        <StaticImage
          src="../../../images/nikke/generic/sim_1.jpg"
          alt="Simulation"
        />
        <p>
          Before you enter the Simulation Room, you have to pick a difficulty.
          There are 5 difficulties available in the game, but at the start only
          the first one will be unlocked - to get access to the rest, you have
          to progress through the Campaign:
        </p>
        <ul>
          <li>Difficulty 2 unlocks after clearing Campaign Stage 5-1,</li>
          <li>Difficulty 3 unlocks after clearing Campaign Stage 8-6,</li>
          <li>Difficulty 4 unlocks after clearing Campaign Stage 11-7,</li>
          <li>Difficulty 5 unlocks after clearing Campaign Stage 14-3.</li>
        </ul>
        <p>
          What's important - even if{' '}
          <strong>
            you're strong enough to clear Difficulty 5, you will automatically
            receive rewards from the lower ones.
          </strong>{' '}
          However, do keep in mind that starting at a higher difficulty will
          mean you will have no legacy buffs stored and will have to start with
          no buffs at the beginning.
        </p>
        <p>
          To avoid making it tedious, the developers added a Sweep feature. Keep
          in mind that Sweep is only available in 3 cases:
        </p>
        <ul>
          <li>
            if your team's power is way higher than the recommended power for
            that stage,
          </li>
          <li>If the stage you’re trying to sweep isn’t a hard stage.</li>
          <li>if the stage you're trying to sweep isn't a boss stage.</li>
        </ul>
        <SectionHeader title="Sectors" />
        <p>
          Each difficulty is split into 3 Sectors (A, B, C), and each Sector is
          split into normal and boss stages - the number of stages starts at 5
          in Sector A, and increases in B and C.
        </p>
        <SectionHeader title="Rooms" />
        <StaticImage
          src="../../../images/nikke/generic/sim_2.jpg"
          alt="Simulation"
        />
        <p>
          Before entering a stage, you will have to choose a room - in some, you
          will have to fight enemies, in others you will heal your characters or
          revive them if they died earlier. But most often the only choice will
          be to fight, but even then you will be able to pick between Normal and
          Hard rooms - as the name suggests, your choice affects the power of
          enemies you will encounter inside. Though, choosing the Hard room
          guarantees you a higher rarity buff - if you manage to clear it that
          is - but make sure your characters are strong enough to tackle it.
        </p>
        <p>List of room types:</p>
        <ul>
          <li>Battle Room - split into normal and hard,</li>
          <li>
            Commanding Ability Test - entering this room is like playing the
            slot machine. Anything can happen - you might receive a powerful
            buff or if you're unlucky, you might lose one of the buffs you
            obtained earlier,
          </li>
          <li>
            ICU - heals all your characters by % of their HP and revises those
            who died,
          </li>
          <li>
            Reinforcement Room - allows you to upgrade some of your buffs or
            combine them together to make them much stronger.
          </li>
        </ul>
        <p>
          The last two rooms always appear in the stage right before the boss
          one and you can pick only one.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Buffs" />
        <StaticImage
          src="../../../images/nikke/generic/sim_3.jpg"
          alt="Simulation"
        />
        <p>
          After clearing a room with enemies, you will be able to pick one of 3
          buffs as a reward that will make your characters stronger.
        </p>
        <p>
          Buffs not only have different rarities - which affects their strength
          - but also some of them can be really specific. For example some buffs
          are for NIKKEs using a specific weapon or belonging to one of the
          manufacturers. So make sure to pick buffs that actually help the
          characters you're using.
        </p>
        <p>
          Also, the buffs you gathered in Sector A will be active in Sectors B
          and C - but only within one Difficulty.
        </p>
        <p>
          After clearing Sector C and finishing a stage, you will be given the
          choice to store them as a Legacy Buff. This allows you to build a
          chain combination going into the next stage and have stronger buffs
          tackling the harder stages.{' '}
        </p>
        <SectionHeader title="Buff Chain" />
        <p>
          After clearing a room with enemies, you will be able to pick one of 3
          buffs as a reward that will make your characters stronger. Each buff
          has a shape type attached to it - "□", "△", "○", or "◇". The more
          buffs belonging to the same type of shapes will level up the buffs
          accordingly and make them stronger.
        </p>
        <p>
          <strong>The levels of the buffs and their strengths are:</strong>
        </p>
        <ul>
          <li>Lv. 1: Basic level</li>
          <li>Lv. 2: Have 3 buffs of the same Chain Type</li>
          <li>Lv. 3: Have 3 buffs of the same Chain Type: (3◇ + 3○)</li>
          <li>Lv. 4: Have 6 buffs of the same Chain Type</li>
          <li>Lv. 5: Have 8 buffs of the same Chain Type</li>
        </ul>
        <StaticImage
          src="../../../images/nikke/generic/sim_4.webp"
          alt="Simulation"
        />
        <SectionHeader title="Legacy Buffs" />
        <p>
          Players who clear a Sector of Simulation Room can choose to save one
          buff from their lineup after they're done with the final stage
          (usually a boss stage). All non-EPIC buffs are eligible to be saved.
          Buff Legacy will NOT trigger if you leave before clearing this boss
          stage.{' '}
        </p>
        <p>
          We want all of our Buffs to be at Lv. 5, hence we want them all to be
          the same shape. We also want all of our Buffs to be SSR in rarity. All
          of this can be achieved as we repeat stages in the normal Simulation
          Room. You can choose to do this over the course of 2 weeks, or in one
          sitting. Keep repeating Sim Room 3B if you cannot quick battle Sim
          Room 5C, otherwise keep re-doing 5C to farm your buff legacy. Your aim
          should be to get the ideal buff list, which we will share our
          recommendations for below.
        </p>
        <p>
          Remember, end goal is all SSR rarity, all of the same shape.
          Nonetheless, it is important to know that you can get a lower rarity
          ideal buff, or a buff in the wrong shape and then use "Spec-Up" or
          "Commanding Abilities Test" to get them to SSR rarity, or to the
          correct shape in your Legacy Chain! Please be wary of this.{' '}
        </p>
        <SectionHeader title="Ideal Legacy Buffs" />
        <Table striped bordered responsive className="sim-table">
          <thead>
            <tr>
              <th>Buff name</th>
              <th>Effect</th>
              <th>Target</th>
              <th>Priority</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={4}>
                <h5 className="red">Must-have buffs</h5>
              </td>
            </tr>
            <tr>
              <td>
                <strong>High-Quality Powder</strong>
              </td>
              <td>
                At the start of a battle, increases ATK by 3.96% / 5.28% / 6.6%
                / 7.92% / 9.24%
              </td>
              <td>Affects All, Affects Attackers, and Affects Manufacturer.</td>
              <td>
                Must have; obtain 3x. Choose manufacturer according to what B3s
                you are using. If using Modernia, SBS, Scarlet, or Redhood, go
                for Pilgrim Manufacturer.
              </td>
            </tr>
            <tr>
              <td>
                <strong>Alva Particle Chaff</strong>
              </td>
              <td>
                Decreases the ATK of the target by 4.5% / 6% / 7.5% / 9% / 10.5%
              </td>
              <td>
                Affects All, Affects Weapon Types (either MG+RL+SR or
                SG+AR+SMG).
              </td>
              <td>
                Must have if low sync. Choose weapon type according to what B3s
                you are using. If using Modernia, SBS, Redhood, or other
                RLs/SRs, go for MG+SR+RL Weapon Types. Otherwise go for the
                SG+AR+SMG Weapon Types. Get two of these if you are below Sync.
                261, otherwise even 1 is enough
              </td>
            </tr>
            <tr>
              <td>
                <strong>Drain Converter</strong>
              </td>
              <td>
                When an attack hits, recover HP equal to 1.98% / 2.64% / 3.3% /
                3.96% / 4.62% of the damage dealt.
              </td>
              <td>Affects all.</td>
              <td>Must Have Buff. One of this buff is enough.</td>
            </tr>
            <tr>
              <td colSpan={4}>
                <h5 className="red">Must-have buffs (unit specific)</h5>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Quick Charger</strong>
              </td>
              <td>
                At the start of a battle, Charge Speed +7.56% / 10.08% / 12.6% /
                15.12% / 17.64%
              </td>
              <td>Affects RLs/SRs.</td>
              <td>
                Must have if using SBS, Redhood, Alice, or other RLs/SRs as main
                B3s. Otherwise do not use.
              </td>
            </tr>
            <tr>
              <td>
                <strong>Hidden Powder</strong>
              </td>
              <td>
                When attacking with Full Charge, deals 37.74% / 50.32% / 62.9% /
                75.48% / 88.06% of ATK as additional damage.
              </td>
              <td>Affects RLs/SRs.</td>
              <td>
                Must have if using SBS, Redhood, Alice or other RLs/SRs as main
                B3s. Otherwise do not use.
              </td>
            </tr>
            <tr>
              <td>
                <strong>Focus Scope</strong>
              </td>
              <td>
                At the start of a battle, increases Hit Rate by 18.12% / 24.16%
                / 30.2% / 36.24% / 42.28%
              </td>
              <td>Affects SG+SMG+AR+MG Weapon Types</td>
              <td>
                Must have buff if using units like Scarlet, SAnis,
                Privaty:Unkind Maid or other AR/SG as main B3 units.
              </td>
            </tr>
            <tr>
              <td colSpan={4}>
                <h5 className="green">Optional buffs</h5>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Impact Inducer</strong>
              </td>
              <td>
                At the start of a battle, increases critical damage by 5.46% /
                7.28% / 9.1% / 10.92% / 12.74%
              </td>
              <td>Affects all.</td>
              <td>
                Optional. Depends on player if they want it or not for increased
                survivability. One is enough.
              </td>
            </tr>
            <tr>
              <td>
                <strong>Homing Guider</strong>
              </td>
              <td>
                At the start of a battle, increases critical rate by 1.86% /
                2.48% / 3.1% / 3.72% / 4.34%
              </td>
              <td>Affects all.</td>
              <td>
                Optional. Depends on player if they want it or not for increased
                survivability. One is enough.
              </td>
            </tr>
            <tr>
              <td>
                <strong>Chain Ammo</strong>
              </td>
              <td>
                10% / 10.5% / 11% / 11.5% / 12% chance to reload 23.1% / 30.8% /
                38.5% / 46.2% / 53.9% of the magazine when empty.
              </td>
              <td>Affects Weapon Types (MG+RL+SR)</td>
              <td>
                Optional. Depends on player if they want it or not for increased
                survivability. One is enough.
              </td>
            </tr>
            <tr>
              <td colSpan={4}>
                <h5 className="arrow-purple">Best epic buffs</h5>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Quick Magazine</strong>
              </td>
              <td>
                At the start of a battle, reduces reload time by 35.35% / 42.42%
                / 49.49% / 56.56% / 63.63%
              </td>
              <td>Affects All</td>
              <td>
                If the player gets this after completing an OverClock stage, he
                should take this immediately. However, if the player thinks
                their own Buff Chain is more valuable, and adding this buff
                breaks the chain (due to it being a different shape), they may
                choose to not take it.
              </td>
            </tr>
            <tr>
              <td>
                <strong>Over Magazine</strong>
              </td>
              <td>
                At the start of a battle, increases loaded ammo by 35.35% /
                42.42% / 49.49% / 56.56% / 63.63%
              </td>
              <td>Affects All</td>
              <td>
                If the player gets this after completing an OverClock stage, he
                should take this if he isnt using any units that have last
                bullet skills that affect gameplay (for example, do not take
                this if dorothy is your main B1 unit)
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
    </DashboardLayout>
  );
};

export default NikkeGuidesGamemodesSimluationPage;

export const Head: React.FC = () => (
  <Seo
    title="Simulation Room | NIKKE | Prydwen Institute"
    description="Guide for the Simulation Room in Goddess of Victory: NIKKE."
  />
);
